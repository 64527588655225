import { render, staticRenderFns } from "./FooterNewsletter.vue?vue&type=template&id=6a3e9e35"
import script from "./FooterNewsletter.vue?vue&type=script&lang=js"
export * from "./FooterNewsletter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IdFooterNewsletterForm: require('/workspace/components/footer/NewsletterForm.vue').default,IdButton: require('/workspace/components/Button.vue').default})
